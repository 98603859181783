<template>
  <div>
    <div>
      <img class="comimg" style="width: 100%" src="@/assets/we/banner.png"/>
    </div>
    <div class="res" style="height: 833px">
      <img class="comimg" style="width: 100%" src="@/assets/we/bg1.png"/>
      <div class="abs home-p-com">
        <div class="com-page-t1">公司简介</div>
        <div style="display: flex;margin-top: 60px">
          <div style="flex: 1;margin-right: 30px">
            <div class="w-c-tit">重庆企修通网络科技有限公司（简称“企修通”）</div>
            <div class="w-c-des">
              成立于2019年，是欧菲斯集团股份有限公司旗下的全资子公司。企修通依托于欧菲斯集团股份有限公司多年政企客户服务经验、行业从业经验和强大的触达B端客户并获客的能力，通过构建和运营企修通平台，是企业办公设备全生命周期的数字化管理平台，致力于实现从企业采购、设备运维、固资盘点、资产处置全流程数字化。
            </div>
            <div class="w-c-tit">产品介绍</div>
            <div class="w-c-des">
              企修通平台是重庆企修通网络科技有限公司创建的一个B2B的产业互联网办公服务平台，搭建企业办公设备全生命周期的数字化管理，围绕设备的采购、运维、盘点以及回收四大重要生命周期节点，构建全数字化的高效管理，同时搭配多场景化服务解决方案，助力企业实现精细化管理。
            </div>
          </div>
          <img style="width: 509px;height: 536px" src="@/assets/we/公司大楼.png">
        </div>
      </div>
    </div>
    <div class="res" style="height: 696px">
      <img class="comimg" style="width: 100%" src="@/assets/we/bg2.png"/>
      <div class="abs  blueline"></div>
      <div class="abs home-p-com">
        <div class="com-page-t1">企修通发展历程</div>
        <div style="margin-top: 102px;">
          <div style="display: flex;justify-content: space-between">
            <div v-for="(item,k) in lcArr" :key="k" :class="['lc-bgt', selectIndex==k?'lcActive':'']"
                 @click="timeClick(item,k)">
              {{item.time}}
            </div>
          </div>
        </div>
        <div class="lc-con">
          <img class="lc-con-img" src="@/assets/we/点缀.png">
          <div class="lc-con-tit">{{selectInfo.timedes}}</div>
          <div class="lc-con-desc">{{selectInfo.content}}</div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 905px">
      <img class="comimg" style="width: 100%" src="@/assets/we/bg3.png"/>
      <img class="abs tjclass" src="@/assets/we/台阶.png">
      <div class="abs home-p-com">
        <div class="com-page-t1">资质荣誉</div>
        <div style="display: flex;margin-top: 30px;justify-content: space-between">
          <div class="zz-img-bg">
            <img :src="zzInfo.icon">
          </div>
          <div style="width: 500px;height: 580px;position: relative;margin-top: 40px">
            <div class="abs sline"></div>
            <div v-for="(item,z) in zzArr" :key="'z'+z" :class="['zzbg',zzIndex==z?'zzActive':'']"
                 @click="zzClick(item,z)">
              <span class="zztit">{{item.time}}</span>
              <div class="zzdot"></div>
              <div class="zzcont">{{item.content}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 752px">
      <img class="comimg" style="width: 100%" src="@/assets/we/bg4.png"/>
      <div class="abs home-p-com">
        <div class="com-page-t1">联系我们</div>
        <div style="display: flex;margin-top: 54px;">
          <img style="width: 713px;height: 457px;margin-right: 20px;margin-left: -10px" src="@/assets/we/地图.png">
          <div style="height: 400px;display: flex;justify-content: space-around;flex-direction: column">
            <div class="we-tit">重庆企修通网络科技有限公司</div>
            <div class="we-c">
              <img class="we-img" src="@/assets/we/ofs-icon_ofs-icon_phone.png">
              <div>
                <div class="we-des">全国统一咨询热线：</div>
                <div class="we-phone">023-8619-3648</div>
              </div>
            </div>
            <div class="we-c"><img class="we-img" src="@/assets/we/ofs-icon_ofs-icon_address2.png">地址：重庆市渝北区金开大道西段106号1幢
            </div>
            <div class="we-c"><img class="we-img" src="@/assets/we/ofs-icon_ofs-icon_email.png">电子邮箱：qixiutong@officemate.cn
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="res" style="height: 302px">
      <img class="comimg" style="width: 100%" src="@/assets/we/bg5.png"/>
      <div class="abs home-p-com cflex" style="flex-direction: column;color: white">
        <div style="text-align: center;font-weight: 600;font-size: 40px;margin-bottom: 10px">提交联系方式，我们会第一时间联系您</div>
        <el-form
            ref="weForm"
            :rules="rules"
            :model="ruleForm"
            label-width="0px"
        >
          <div class="uploadMsg">
            <img class="upload-img" src="@/assets/we/ofs-i-gongsi.png">
            <el-form-item label="" prop="companyName">
              <el-input
                  style="width: 245px;margin-top: 20px"
                  v-model="ruleForm.companyName"
                  placeholder="公司名称"
              ></el-input>
            </el-form-item>
            <img class="upload-img" src="@/assets/we/ofs-icon_login-zhangxiaodelu.png">
            <el-form-item label="" prop="contactorName">
              <el-input
                  v-model="ruleForm.contactorName"
                  style="width: 245px;margin-top: 20px"
                  placeholder="姓名"
              ></el-input>
            </el-form-item>
            <img class="upload-img" src="@/assets/we/ofs-i-phone.png">
            <el-form-item label="" prop="contactorPhone">
              <el-input
                  style="width: 245px;margin-top: 20px"
                  v-model="ruleForm.contactorPhone"
                  placeholder="电话"
              ></el-input>
            </el-form-item>
            <div class="e-btn"
                 style="background: linear-gradient(180deg, #4189FF 0%, #034BFF 100%);color: white;margin-left: 12px"
                 @click="save">免费试用
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "aboutUs",
  data () {
    var validateMobile = (rule, value, callback) => {
      let reg = /^1[3-9]\d{9}$/;
      if (value == "" || !value) {
        callback(new Error("请输入电话"));
      } else {
        if (!reg.test(value)) {
          callback(new Error("请检查手机格式"));
        } else {
          callback();
        }
      }
    };
    return {
      loading: false,
      ruleForm: {},
      rules: {
        companyName: [
          {required: true, message: "请输入公司名称", trigger: "blur"},
        ],
        contactorName: [
          {required: true, message: "请输入姓名", trigger: "blur"},
        ],
        contactorPhone: [{validator: validateMobile, trigger: "blur"}],
      },
      zzIndex: 0,//资质荣誉
      zzInfo: {
        time: '2022年',
        icon: require('@/assets/we/企修通服务商端软著登记证书.png'),
        isSelect: true,
        content: '企修通服务商端软著登记证书',
      },//资质荣誉
      selectIndex: 0,//发展历程
      selectInfo: {
        time: '2022-5',
        timedes: '2022年5月',
        isSelect: true,
        content: '企修通全新定位，逐渐市场化。围绕设备的采购、运维、盘点以及处置重要生命周期节点，打造数字化的管理平台。',
      },//发展历程
      lcArr: [
        {
          time: '2022-5',
          timedes: '2022年5月',
          isSelect: true,
          content: '企修通全新定位，逐渐市场化。围绕设备的采购、运维、盘点以及处置重要生命周期节点，打造数字化的管理平台。',
        },
        {
          time: '2020~2022',
          timedes: '2020年-2022年',
          isSelect: false,
          content: '企修通在重庆、成都、广东、沈阳、山西等城市，主要用于集团内部及各子公司的工单管理。',
        },
        {
          time: '2020-5',
          timedes: '2020年5月',
          isSelect: false,
          content: '经过三年打磨，全新版企修通重新上线。新版本主要用于维保、租赁业务。',
        },
        {
          time: '2019',
          timedes: '2019年',
          isSelect: false,
          content: '重庆企修通网络科技有限公司正式成立，“维修通”正式更名为“企修通”。',
        },
        {
          time: '2017~2019',
          timedes: '2017年——2019年',
          isSelect: false,
          content: '试点运营——重庆、成都。维修通项目在两大城市落地进行试点运行。',
        },
        {
          time: '2017-7',
          timedes: '2017年7月',
          isSelect: false,
          content: '7月维修通项目正式上线，用户通过公众号进行设备的扫码报修。',
        },
        {
          time: '2017',
          timedes: '2017年',
          isSelect: false,
          content: '2017年初，集团成立企修通前身“维修通”项目，专门为客户提供办公设备售后、报修服务。',
        },
      ],
      zzArr: [
        {
          time: '2022年',
          icon: require('@/assets/we/企修通服务商端软著登记证书.png'),
          isSelect: true,
          content: '企修通服务商端软著登记证书',
        },
        {
          time: '2022年',
          icon: require('@/assets/we/企修通工程师端软著登记证书.png'),
          isSelect: false,
          content: '企修通工程师端软著登记证书',
        },
        {
          time: '2022年',
          icon: require('@/assets/we/企修通客户端软著登记证书.png'),
          isSelect: false,
          content: '企修通客户端软著登记证书',
        },
        {
          time: '2022年',
          icon: require('@/assets/we/企修通用户端软著登记证书.png'),
          isSelect: false,
          content: '企修通用户端软著登记证书',
        },
        {
          time: '2022年',
          icon: require('@/assets/we/企修通运营端软著登记证书.png'),
          isSelect: false,
          content: '企修通运营端软著登记证书',
        },
        {
          time: '2022年',
          icon: require('@/assets/we/信创认证.png'),
          isSelect: false,
          content: '信创认证',
        },
        {
          time: '2022年',
          icon: require('@/assets/we/信息系统等级保护三级备案证明.png'),
          isSelect: false,
          content: '信息系统等级保护三级备案证明',
        },
      ],
    }
  },
  methods: {
    save () {
      let that = this;
      that.loading = true;
      this.$refs.weForm.validate((valid) => {
        if (valid) {
          that.loading = true;
          this.$api.saveMessage(that.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "您的试用申请已提交，请耐心等待工作人员联系",
                type: "success",
              });
              that.loading = false;
            } else if (res.code == 500 && res.msg == "您已提交过此申请") {
              this.$message({
                message: res.msg,
                type: "warning",
              });
              that.loading = false;
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        } else {
          that.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    timeClick (item, k) {
      this.selectIndex = k
      this.selectInfo = item
    },
    zzClick (item, z) {
      this.zzIndex = z
      this.zzInfo = item
    },
  }
}
</script>

<style scoped>
  .uploadMsg {
    width: 1015px;
    height: 72px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    align-items: center;
  }

  .upload-img {
    width: 32px;
    height: 32px;
    margin: 0 0 0px 10px;
  }

  .w-c-tit {
    height: 40px;
    font-size: 28px;
    font-weight: 600;
    color: #1F5FFF;
    line-height: 36px;
    padding-left: 10px;
    position: relative;
    margin-bottom: 20px;
  }

  .w-c-tit:before {
    position: absolute;
    content: '';
    left: 0px;
    top: 6px;
    width: 4px;
    height: 26px;
    background: linear-gradient(180deg, #4189FF 0%, #1F5FFF 100%);
  }

  .w-c-des {
    font-size: 22px;
    font-weight: 400;
    color: #35363F;
    line-height: 38px;
    margin-bottom: 50px;
  }

  .lc-bgt {
    font-size: 22px;
    font-weight: 400;
    color: #35363F;
    line-height: 18px;
    height: 26px;
    width: 80px;
    text-align: left;
    transform: rotate(-30deg);
    position: relative;
    cursor: pointer;
  }

  .lc-bgt:before {
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    bottom: -39px;
    left: -20px;
    border-radius: 50%;
    background: #D4D4D4;
    border: 5px solid #FFFFFF;
    box-shadow: 2px 2px 6px 1px rgba(53, 54, 63, 0.1);

  }

  .lc-bgt:hover {
    color: #1F5FFF;
  }

  .lc-bgt:hover:before {
    /*transform: scale(1.3);*/
    background: #1F5FFF;
  }

  .lcActive {
    color: #1F5FFF !important;
  }

  .lcActive:before {
    /*transform: scale(1.3) !important;*/
    background: #1F5FFF !important;
  }

  .blueline {
    left: 0;
    top: 316px;
    width: 100vw;
    height: 1px;
    background: #9BCEFF;
  }

  .lc-con {
    width: 1122px;
    left: 39px;
    height: 210px;
    margin-top: 100px;
    margin-left: -10px;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(180deg, #F9FCFF 0%, #ECF3FF 100%);
    box-shadow: 10px 10px 10px 1px rgba(31, 95, 255, 0.1);
    border-radius: 18px 18px 18px 18px;
    border: 2px solid #FFFFFF;
    position: relative;
  }

  .lc-con-img {
    position: absolute;
    right: -4px;
    top: -8px;
    width: 32px;
    height: 36px;
  }

  .lc-con-tit {
    font-size: 32px;
    font-weight: bold;
    color: #1F5FFF;
    line-height: 18px;
  }

  .lc-con-desc {
    margin-top: 16px;
    font-size: 24px;
    font-weight: 400;
    color: #171717;
    line-height: 38px;
  }

  .zz-img-bg {
    width: 688px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 598px;
    margin-left: -50px;
  }

  .zzbg {
    display: flex;
    align-items: center;
    height: 80px;
  }

  .zzActive .zztit {
    font-weight: 600;
    color: #1F5FFF;
  }

  .zzActive .zzdot {
    background: white;
    border: 4px solid #1f5fff;
  }

  .zzActive .zzcont {
    background: linear-gradient(180deg, #4189FF 0%, #1F5FFF 100%);
    box-shadow: 10px 10px 10px 1px rgba(31, 95, 255, 0.1);
    color: white;
  }

  .zzActive .zzcont:before {
    border-color: transparent #4189FF transparent transparent;
  }

  .zzbg:hover .zztit {
    font-weight: 600;
    color: #1F5FFF;
  }

  .zzbg:hover .zzdot {
    background: white;
    border: 4px solid #1f5fff;
  }

  .zzbg:hover .zzcont {
    background: linear-gradient(180deg, #4189FF 0%, #1F5FFF 100%);
    box-shadow: 10px 10px 10px 1px rgba(31, 95, 255, 0.1);
    color: white;
  }

  .zzbg:hover .zzcont:before {
    border-color: transparent #1f5fff transparent transparent;
  }

  .zztit {
    font-size: 24px;
    width: 102px;
    font-weight: 400;
    color: #35363F;
    line-height: 18px;
  }

  .zzdot {
    width: 12px;
    height: 12px;
    background: #4189FF;
    border: 4px solid #4189FF;
    border-radius: 50%;
  }

  .zzcont {
    width: 352px;
    height: 64px;
    border-radius: 8px 8px 8px 8px;
    font-size: 24px;
    font-weight: 400;
    color: #35363F;
    line-height: 64px;
    padding-left: 20px;
    margin-left: 20px;
    position: relative;
  }

  .zzcont:before {
    position: absolute;
    content: '';
    top: 30px;
    left: -10px;
    width: 0;
    height: 0;
    border-width: 4px 10px 4px 0;
    border-style: solid;
    border-color: transparent;
  }

  .sline {
    top: 0;
    left: 108px;
    width: 1px;
    height: 580px;
    background: #1f5fff;
    z-index: -1;
  }

  .tjclass {
    left: calc(50vw - 600px);
    bottom: 0;
    width: 600px;
    height: 220px;
  }

  .we-tit {
    font-size: 32px;
    font-weight: 600;
    color: #171717;
    line-height: 18px;
    position: relative;
    margin-bottom: 104px;
    margin-top: 60px;
  }

  .we-tit:before {
    position: absolute;
    left: 0;
    bottom: -30px;
    content: '';
    width: 38px;
    height: 4px;
    background: #1F5FFF;
  }

  .we-c {
    display: flex;
    height: 36px;
    font-size: 22px;
    align-items: center;
  }

  .we-des {
    font-size: 14px;
    font-weight: 400;
    color: #35363F;
  }

  .we-phone {
    font-size: 22px;
    font-weight: bold;
    color: #1F5FFF;
  }

  .we-img {
    width: 36px;
    height: 36px;
    margin-right: 5px;
  }
</style>
